/*               */
import mustache from "mustache";

Object.defineProperty(window, "Mustache", {
  get() {
    const { stack } = new Error();
    otto.debug.sendLog("assets-legacy", {
      stack,
      message: "Mustache is deprecated",
      caller: stack?.split("\n")[2],
    });
    return mustache;
  },
});
