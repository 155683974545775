/*                                                         */
import type { Orientation, OrientationType } from "../device/head.js";
import type { Breakpoint, Breakpoints } from "./index.js";

const { submitEvent } = otto.tracking;

declare module "@otto-ec/rum-client" {
  interface Assets {
    trackingHandler: {
      setCurrentBreakpoint(bp: string): void;
      setCurrentOrientation(orientation: string): void;
      getCurrentBreakpoint(): string;
      getCurrentOrientation(): string;
      updateLocalVars(): void;
      handleTrackingEvent(orientationChanged: boolean, breakpointChanged: boolean): void;
      breakpoint: Breakpoint;
      orientation: Orientation;
    };
  }
}

const { breakpoint } = window.o_global.device;
let _currentBreakpoint = breakpoint.getCurrentBreakpoint();
const { orientation } = window.o_global.device;
let _currentOrientation = orientation.getCurrentOrientation();
let breakpointTimer: number | undefined | NodeJS.Timeout;
let orientationTimer: number | undefined | NodeJS.Timeout;

/**
 *
 */
function updateLocalVars(): void {
  _currentOrientation = orientation.getCurrentOrientation();
  _currentBreakpoint = breakpoint.getCurrentBreakpoint();
}

/**
 *
 *
 *
 *
 *
 */
function sendTracking(orientationChanged: boolean, breakpointChanged: boolean): void {
  const sendData = {
    ot_BreakpointChange: [breakpointChanged.toString()],
    ot_OrientationChange: [orientationChanged.toString()],
  };

  if (window.o_util.misc.isPreview(window.location.href)) {
    return;
  }

  submitEvent(sendData);
}

/**
 *
 *
 *
 *
 *
 */
function handleTrackingEvent(orientationChanged: boolean, breakpointChanged: boolean): void {
  /*                                                                                                                 */
  /*                                                                        */
  /*                                                               */
  if (orientationChanged && !breakpointChanged) {
    /*                                                                                         */
    orientationTimer = setTimeout(function () {
      if (_currentBreakpoint === breakpoint.getCurrentBreakpoint()) {
        sendTracking(true, false);
        _currentOrientation = orientation.getCurrentOrientation();
      } else {
        clearTimeout(breakpointTimer);
        breakpointTimer = undefined;
        sendTracking(true, true);
        updateLocalVars();
      }
    }, 500);
  } else {
    /*                                                                                         */
    breakpointTimer = setTimeout(function () {
      /*                                                                                 */
      if (!breakpointTimer) {
        return;
      }

      if (_currentOrientation === orientation.getCurrentOrientation()) {
        /*                                          */
        sendTracking(false, true);
        _currentBreakpoint = breakpoint.getCurrentBreakpoint();
      } else {
        clearTimeout(orientationTimer);
        orientationTimer = undefined;
        sendTracking(true, true);
        updateLocalVars();
      }
    }, 500);
  }
}

/*           */
/**
 *
 *
 *
 */
function getCurrentOrientation(): string {
  return _currentOrientation;
}

/**
 *
 *
 *
 */
function setCurrentOrientation(cO: OrientationType): void {
  _currentOrientation = cO;
}

/**
 *
 *
 *
 */
function getCurrentBreakpoint(): string {
  return _currentBreakpoint;
}

/**
 *
 *
 *
 */
function setCurrentBreakpoint(cB: Breakpoints | "unknown"): void {
  _currentBreakpoint = cB;
}
/*               */

orientation.registerChangeListener(function () {
  handleTrackingEvent(true, false);
});

/*                                                          */
/*                                                                                      */
breakpoint.registerChangeListener(function () {
  handleTrackingEvent(false, true);
});

/**
 *
 *
 *
 *
 */
window.AS.trackingHandler = {
  ...window.AS.trackingHandler,
  /*           */
  handleTrackingEvent,
  updateLocalVars,
  getCurrentBreakpoint,
  getCurrentOrientation,
  setCurrentBreakpoint,
  setCurrentOrientation,
  breakpoint,
  orientation,
  /*               */
};
