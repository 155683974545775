import { o_global } from "@gr-common/body/namespaces";
import { enrichPageImpression } from "./pageImpression";

import { Hybrid, hybrid } from "./lib/hybrid";
import { Orientation, orientation } from "./lib/orientation";
import { isTouchable } from "./lib/touch";
import { screen } from "./lib/screen";
import { os } from "./lib/os";
import { browser } from "./lib/browser";
import { type } from "./lib/type";

export * from "./lib/browser";
export type * from "./lib/events";
export * from "./lib/hybrid";
export * from "./lib/orientation";
export * from "./lib/os";
export * from "./lib/screen";
export * from "./lib/touch";
export * from "./lib/type";
export * from "./lib/preferences";

const device = {
  Hybrid,
  Orientation,
  isTouchable: isTouchable(),
  os: os(),
  screen,
  browser: browser(),
  orientation: orientation(),
  hybrid: undefined as unknown as Hybrid,
  type: type(),
  /*                                                   */
  /*                                                */
  breakpoint: o_global.breakpoint,
};

export type GlobalDevice = typeof device;

/*                                                */
o_global.device = device;
o_global.device.hybrid = hybrid();

if (window.o_util?.toggle?.get("ASSETS_device_preferences", false)) {
  enrichPageImpression();
}

if (window.o_util.toggle.get("asssets_track_dpr", false)) {
  otto.debug.sendLog("assets_dpr", {
    dpi: o_global.device.screen.getDevicePixelRatio(),
  });
}

otto.device.assignNamespace({
  getPointerType: () => ({ isTouchable: device.isTouchable, isHybrid: device.hybrid.isHybrid }),
});
