/**
 *
 *
 *
 *
 */
import { Breakpoint as BreakpointType } from "../lib/breakpoint/index.js";

export type {
  BreakpointChangedCallback,
  BreakpointOptions,
  Breakpoints,
} from "../lib/breakpoint/index.js";

/*                                                                */
type Breakpoint = BreakpointType;
/*                       */
const { breakpoint, Breakpoint } = window.o_global ?? {};

export { breakpoint, Breakpoint };
