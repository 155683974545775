/*                                                                   */
import { o_global } from "@gr-common/body/namespaces";

/**
 *
 *
 *
 */
export interface BreakpointOptions {
  /**
 *
 */
  small: MediaQueryList;
  /**
 *
 */
  medium: MediaQueryList;
  /**
 *
 */
  large: MediaQueryList;
  /**
 *
 */
  extraLarge: MediaQueryList;
}

/**
 *
 *
 *
 *
 */
export type BreakpointChangedCallback = (breakpoint: Breakpoints) => void;

/**
 *
 *
 *
 *
 *
 */
export type Breakpoints = "s" | "m" | "l" | "xl";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export class Breakpoint {
  /**
 *
 */
  public readonly breakpoints: BreakpointOptions;

  /**
 *
 *
 */
  constructor(breakpoints: BreakpointOptions) {
    this.breakpoints = breakpoints;
  }

  /**
 *
 *
 *
 */
  getBreakpoints(): BreakpointOptions {
    return this.breakpoints;
  }

  /**
 *
 *
 *
 */
  isSmall(): boolean {
    return this.breakpoints.small.matches;
  }

  /**
 *
 *
 *
 */
  isMedium(): boolean {
    return this.breakpoints.medium.matches;
  }

  /**
 *
 *
 *
 */
  isLarge(): boolean {
    return this.breakpoints.large.matches;
  }

  /**
 *
 *
 *
 */
  isExtraLarge(): boolean {
    return this.breakpoints.extraLarge.matches;
  }

  /**
 *
 *
 *
 */
  getCurrentBreakpoint(): Breakpoints | "unknown" {
    let result: Breakpoints | "unknown" = "unknown";

    if (this.isSmall()) {
      result = "s";
    } else if (this.isMedium()) {
      result = "m";
    } else if (this.isLarge()) {
      result = "l";
    } else if (this.isExtraLarge()) {
      result = "xl";
    }

    return result;
  }

  /**
 *
 *
 *
 *
 */
  isBreakpointActive(breakpointList: Breakpoints[]): boolean {
    const currentBreakpoint = this.getCurrentBreakpoint();
    return currentBreakpoint !== "unknown" && breakpointList.includes(currentBreakpoint);
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  registerChangeListener(callback: BreakpointChangedCallback): void {
    function getListener(bPoint: Breakpoints) {
      /*                                                         */

      return function breakpointListener(breakpoints: MediaQueryListEvent) {
        if (breakpoints.matches) {
          callback(bPoint);
        }
      };
    }

    if (this.breakpoints.small.addEventListener) {
      this.breakpoints.small.addEventListener("change", getListener("s"));
      this.breakpoints.medium.addEventListener("change", getListener("m"));
      this.breakpoints.large.addEventListener("change", getListener("l"));
      this.breakpoints.extraLarge.addEventListener("change", getListener("xl"));
    } else {
      /*                                                                                 */
      /*                                                                                          */
      this.breakpoints.small.addListener(getListener("s"));
      this.breakpoints.medium.addListener(getListener("m"));
      this.breakpoints.large.addListener(getListener("l"));
      this.breakpoints.extraLarge.addListener(getListener("xl"));
    }
  }
}

/**
 *
 *
 */
export function breakpoint(): Breakpoint {
  return new Breakpoint({
    small: window.matchMedia("(min-width: 0em) and (max-width: 27.99em)"),
    medium: window.matchMedia("(min-width: 28em) and (max-width: 47.99em)"),
    large: window.matchMedia("(min-width: 48em) and (max-width: 61.99em)"),
    extraLarge: window.matchMedia("(min-width: 62em)"),
  });
}

o_global.breakpoint ||= breakpoint();
/*                                                          */
(o_global as any).breakpointClass ||= Breakpoint;
o_global.Breakpoint ||= Breakpoint;

/*                                                  */
o_global.device ||= {} as never;
o_global.device.breakpoint ||= window.o_global.breakpoint;
