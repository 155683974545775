/**
 *
 *
 *
 *
 */

import type { QBusEventMap } from "@otto-ec/event-q-bus";
import type { PreloadEvents } from "@otto-ec/preload-polyfill";
import type { EventLoaderEvents } from "./event-loader.js";

export type {
  QBus,
  QBusEventEnum,
  QBusEventMap,
  QBusOptions,
  QBusTopic,
} from "@otto-ec/event-q-bus";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export type GlobalEvents = QBusEventMap<EventLoaderEvents & PreloadEvents>;

export const { events, eventQBus } = window.o_global ?? {};
