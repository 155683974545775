/*                                                         */
/**
 *
 *
 *
 *
 */

window.lhotse ||= {};
window.lhotse.exactag ||= {};
window.lhotse.exactag.centralFinished = false;
window.lhotse.exactag.finished = false;
/**
 *
 *
 */
window.lhotse.exactag.execute = false;

/*             */
window.lhotse.exactag.varnish = {
  version: "0.8.9",
  data: [],
};

/*    */
/*                                       */
/*  */
/*                                                      */
/*  */
/*       */
/*                                                                     */
/*                                                  */
/*    */
/*                                        */
(function (win) {
  const log = win.o_global.debug.logger("exactag.varnish");
  const { getConsentForGV, consentChanged } = otto.cmp;

  const { host } = win.location;
  const env = `${win.location.protocol}/*       */
  const varnishURL = `${env}/user/performanceMarketing`;
  const firstChar = varnishURL.substring(0, 1);
  const { hash } = win.location;
  let variationId = "";
  const EMERGENCY_PXC_TOGGLE = "EMR_PXC";
  /*                                                            */
  const re = /variationId=([a-zA-Z0-9_-]*)/;

  /*                                          */
  /*                                                          */
  if (re.test(hash)) {
    variationId = `?variationId=${hash.match(re)[1]}`;
  }

  /*                                                                        */
  window.lhotse.exactag.getVarnishData = function (url) {
    window.o_util.ajax
      .getScript(url)
      .then(function (xhr) {
        /*                                                  */
        /*                                                                     */
        if (xhr.status === 200 || xhr.status === 304) {
          /*                                  */
          /*                                                      */
          window.lhotse.exactag.finished = true;

          /*                                                                                     */
          /*                                                                                    */
          if (window.lhotse.exactag.centralFinished) {
            window.lhotse.exactag.continueTrack();
          }
        } else {
          /*                                  */
          const msg = `Triggered ajaxError handler in '${varnishURL}'! Status: ${xhr.status}`;

          /*                        */
          log.debug(msg);
        }
      })
      .catch((e) => {
        log.warn(e.message);
      });
  };

  const execute = () => {
    /*                                                                                                 */
    if (window.lhotse.exactag.varnishURL) {
      if (firstChar === "." || firstChar === "/") {
        window.lhotse.exactag.getVarnishData(varnishURL);
      }
    } else {
      window.lhotse.exactag.getVarnishData(varnishURL + variationId);
    }
  };

  const eprivacyConsent = "eprivacy.cmp.consentChanged";

  window.lhotse.exactag.consents = {};
  window.lhotse.exactag.consents[eprivacyConsent] = {
    received: false,
    accept: false,
  };
  window.lhotse.exactag.consentResult = { received: false, accept: false };

  const processConsentAnswer = (consent, yes) => {
    window.lhotse.exactag.consents[consent].received = true;
    window.lhotse.exactag.consents[consent].accept = yes;

    /**
 *
 */
    const consentResult = { received: true, accept: true };
    window.lhotse.exactag.consentResult = consentResult;

    Object.keys(window.lhotse.exactag.consents).forEach((k) => {
      consentResult.received = consentResult.received && window.lhotse.exactag.consents[k].received;
      consentResult.accept = consentResult.accept && window.lhotse.exactag.consents[k].accept;
    });

    /*                                                */
    if (consentResult.received && consentResult.accept) {
      window.lhotse.exactag.execute = true;
      execute();
    }
  };

  window.lhotse.exactag.hasConsent = () => {
    try {
      return (
        window.lhotse.exactag.getConsentForGV("OTTOT") &&
        window.o_util.toggle.get(EMERGENCY_PXC_TOGGLE, true) &&
        !window.o_util.misc.isPreview(window.location.href) &&
        !window.o_util.misc.isPrivacyPage()
      );
    } catch {
      return false;
    }
  };

  /*                                        */
  /*                                                                */
  window.lhotse.exactag.onLoad = async () => {
    window.lhotse.exactag.getConsentForGV = await getConsentForGV;

    processConsentAnswer(eprivacyConsent, window.lhotse.exactag.hasConsent());

    /*                                           */
    /*                  */
    /*                                            */
    if (!window.lhotse.exactag.consentResult.received) {
      /*                                 */
      execute();
    }
  };

  /*                                                     */
  consentChanged.on(async () => {
    /*                                                                           */
    window.lhotse.exactag.getConsentForGV = await getConsentForGV;
    await window.lhotse.exactag.addPartnerConsents();
    processConsentAnswer(eprivacyConsent, window.lhotse.exactag.hasConsent());
  });

  window.o_global.eventLoader.onLoad(21, function () {
    window.lhotse.exactag.onLoad();
  });
})(window);

export {};
