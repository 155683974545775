import { o_global } from "@gr-common/body/namespaces";
import { breakpoint } from "../../exports/breakpoint";

/**
 *
 *
 *
 */
function setDevSpecsCookie(): void {
  const bp = `bp=${breakpoint.getCurrentBreakpoint()}`;
  const {
    documentElement: { clientHeight, clientWidth },
  } = document;

  const cookieValue = `w=${clientWidth}&h=${clientHeight}&${bp}`;
  const expireDate = new Date();

  expireDate.setTime(expireDate.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);
  document.cookie = `devSpecs=${cookieValue};path=/;domain=.otto.de;expires=${expireDate.toUTCString()}`;
}

/**
 *
 */
function handleBreakpointChange(): void {
  try {
    breakpoint.registerChangeListener(setDevSpecsCookie);
  } catch (e) {
    /* */
  }
}

/**
 *
 */
o_global.rwdTools ||= { setDevSpecsCookie, handleBreakpointChange };

/*                           */
setDevSpecsCookie();
handleBreakpointChange();
