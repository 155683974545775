/*                                                  */
import { preferredColorScheme, preferredContrast } from "./lib/preferences.js";
import type { DataContainer } from "../../ottoec/tracking-bct/exports.js";

export function enrichPageImpression(): void {
  const pageImpression: DataContainer = {
    ot_PreferredColorScheme: [preferredColorScheme()],
  };

  const contrastLevel = preferredContrast();
  if (contrastLevel) {
    pageImpression.ot_PreferredContrast = [contrastLevel];
  }

  otto.tracking.submitMerge(pageImpression);
}
